import img1 from "../../images/img8.webp";
import img2 from "../../images/img2.webp";
import img3 from "../../images/img3.webp";
import img4 from "../../images/img1.webp";

export const SliderDataMachines = [
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img4,
  },
];
