import img1 from "../../images/img4.webp";
import img2 from "../../images/img7.webp";
import img3 from "../../images/img5.webp";
import img4 from "../../images/img6.webp";

export const SliderData = [
  {
    image: img1,
  },
  {
    image: img2,
  },
  {
    image: img3,
  },
  {
    image: img4,
  },
];
